import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthServiceProvider } from 'common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate {

  constructor(private authServiceProvider: AuthServiceProvider,
              private router: Router) {
  }

  canActivate(_: ActivatedRouteSnapshot, _2: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authServiceProvider.service.isLogged()
      .pipe(
        map(logged => {
          if (!logged) {
            this.router.navigate(['/authentication']);
          }
          return logged;
        }),
      );
  }

}
