import { Component } from '@angular/core';
import { ModalHelperService } from 'common';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  displayMask = false;

  constructor(private modalHelperService: ModalHelperService) {
    this.modalHelperService.listenDisplayMask()
      .subscribe((display: boolean) => {
        this.displayMask = display;
      });
  }
}
