import { forwardRef, Inject, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiModule, CaplockCommonModule } from 'common';
import { BackofficeApiModule } from "../api/backoffice-api.module"
import { ApiInterceptor } from './interceptor/api-interceptor';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
// @ts-ignore
import { DOCUMENT, registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Amplify } from "aws-amplify";
import {
  CreateFactoryFormComponent
} from "./component/create-factory-form/create-factory-form.component";
import { FileAccessService } from "./service/file-access.service";
import { FileSystemService } from "./service/file-system.service";
import { MembershipService } from "./service/membership.service";
import { FactoryFolderService } from "./service/factory-folder.service";
import { NetworkService } from "./service/network.service";
import { ResourceService } from "./service/resource.service";
import { SelectPictureService } from "./service/select-picture.service";
import { ColorPickerModule } from "ngx-color-picker";
import { InstructionService } from "./service/instruction.service";
import { OrganService } from "./service/organ.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

registerLocaleData(localeFr);

Amplify.configure({
  Auth: {
    region: environment.awsRegion,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
  }
});

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

export function HttpLoaderFactory(httpHandler: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpHandler));
}

@NgModule({
  declarations: [AppComponent, CreateFactoryFormComponent],
  imports: [
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ApiModule.forRoot({rootUrl: environment.serverUrl}),
    BackofficeApiModule.forRoot({rootUrl: environment.serverUrl}),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    CaplockCommonModule.forRoot({
      environment: environment.name,
      serverUrl: environment.serverUrl,
      websocketUrl: undefined,
      mobile: false,
      awsPictureBucket: environment.awsPictureBucket,
      awsPublicBucket: environment.awsPublicBucket,
      webUrl: environment.webUrl,
    }),
  ],
  providers: [
    {provide: 'FileAccessService', useClass: FileAccessService},
    {provide: 'FileSystemService', useClass: FileSystemService},
    {provide: 'InstructionService', useClass: InstructionService},
    {provide: 'OrganService', useClass: OrganService},
    {provide: 'MembershipService', useClass: MembershipService},
    {provide: 'FactoryFolderService', useClass: FactoryFolderService},
    {provide: 'NetworkService', useClass: NetworkService},
    {provide: 'ResourceService', useClass: ResourceService},
    {provide: 'SelectPictureService', useClass: SelectPictureService},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(DOCUMENT) private document: Document,
              private translateService: TranslateService) {
    // set language
    translateService.use(translateService.defaultLang);
    this.document.documentElement.lang = translateService.currentLang;
  }
}
