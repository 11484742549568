/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AllStatsDto } from '../models/all-stats-dto';
import { CreateFactoryDto } from '../models/create-factory-dto';
import { Email } from '../models/email';
import { FactoryKpiDto } from '../models/factory-kpi-dto';
import { FactoryLimitDto } from '../models/factory-limit-dto';
import { Id } from '../models/id';
import { MissingPictureDto } from '../models/missing-picture-dto';

@Injectable({
  providedIn: 'root',
})
export class BackofficeApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getKpis
   */
  static readonly GetKpisPath = '/backoffice-api/kpi';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKpis()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKpis$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FactoryKpiDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.GetKpisPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FactoryKpiDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getKpis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKpis(params?: {
    context?: HttpContext
  }
): Observable<Array<FactoryKpiDto>> {

    return this.getKpis$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FactoryKpiDto>>) => r.body as Array<FactoryKpiDto>)
    );
  }

  /**
   * Path part for operation getStats
   */
  static readonly GetStatsPath = '/backoffice-api/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStats$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AllStatsDto>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.GetStatsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AllStatsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStats(params?: {
    context?: HttpContext
  }
): Observable<AllStatsDto> {

    return this.getStats$Response(params).pipe(
      map((r: StrictHttpResponse<AllStatsDto>) => r.body as AllStatsDto)
    );
  }

  /**
   * Path part for operation createFactoryFromBackoffice
   */
  static readonly CreateFactoryFromBackofficePath = '/backoffice-api/factory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFactoryFromBackoffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFactoryFromBackoffice$Response(params?: {
    context?: HttpContext
    body?: CreateFactoryDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.CreateFactoryFromBackofficePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFactoryFromBackoffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFactoryFromBackoffice(params?: {
    context?: HttpContext
    body?: CreateFactoryDto
  }
): Observable<void> {

    return this.createFactoryFromBackoffice$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteFactory
   */
  static readonly DeleteFactoryPath = '/backoffice-api/factory/{factoryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFactory()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFactory$Response(params: {
    factoryId: Id;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.DeleteFactoryPath, 'delete');
    if (params) {
      rb.path('factoryId', params.factoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFactory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFactory(params: {
    factoryId: Id;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteFactory$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createDemoFactory
   */
  static readonly CreateDemoFactoryPath = '/backoffice-api/demoFactory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDemoFactory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDemoFactory$Response(params?: {
    context?: HttpContext
    body?: CreateFactoryDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.CreateDemoFactoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDemoFactory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDemoFactory(params?: {
    context?: HttpContext
    body?: CreateFactoryDto
  }
): Observable<void> {

    return this.createDemoFactory$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateFactoryLimits
   */
  static readonly UpdateFactoryLimitsPath = '/backoffice-api/factory/{factoryId}/limit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFactoryLimits()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFactoryLimits$Response(params: {
    factoryId: Id;
    context?: HttpContext
    body?: FactoryLimitDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.UpdateFactoryLimitsPath, 'put');
    if (params) {
      rb.path('factoryId', params.factoryId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFactoryLimits$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFactoryLimits(params: {
    factoryId: Id;
    context?: HttpContext
    body?: FactoryLimitDto
  }
): Observable<void> {

    return this.updateFactoryLimits$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation regenerateAllQrCode
   */
  static readonly RegenerateAllQrCodePath = '/backoffice-api/action/regenerateAllQrCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `regenerateAllQrCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAllQrCode$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.RegenerateAllQrCodePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `regenerateAllQrCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAllQrCode(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.regenerateAllQrCode$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation regenerateAllInstruction
   */
  static readonly RegenerateAllInstructionPath = '/backoffice-api/action/regenerateAllInstruction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `regenerateAllInstruction()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAllInstruction$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.RegenerateAllInstructionPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `regenerateAllInstruction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAllInstruction(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.regenerateAllInstruction$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation regenerateAllTasks
   */
  static readonly RegenerateAllTasksPath = '/backoffice-api/action/regenerateAllTasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `regenerateAllTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAllTasks$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.RegenerateAllTasksPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `regenerateAllTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAllTasks(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.regenerateAllTasks$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation fixResource
   */
  static readonly FixResourcePath = '/backoffice-api/fixResource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fixResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  fixResource$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.FixResourcePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fixResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fixResource(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.fixResource$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation generatePredictions
   */
  static readonly GeneratePredictionsPath = '/backoffice-api/prediction';

  /**
   * generate predictions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generatePredictions()` instead.
   *
   * This method doesn't expect any request body.
   */
  generatePredictions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.GeneratePredictionsPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * generate predictions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generatePredictions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generatePredictions(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.generatePredictions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation archiveUser
   */
  static readonly ArchiveUserPath = '/backoffice-api/user/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archiveUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveUser$Response(params: {
    email: Email;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ArchiveUserPath, 'delete');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `archiveUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  archiveUser(params: {
    email: Email;
    context?: HttpContext
  }
): Observable<void> {

    return this.archiveUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getMissingPictures
   */
  static readonly GetMissingPicturesPath = '/backoffice-api/missing-pictures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMissingPictures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMissingPictures$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MissingPictureDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.GetMissingPicturesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MissingPictureDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMissingPictures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMissingPictures(params?: {
    context?: HttpContext
  }
): Observable<Array<MissingPictureDto>> {

    return this.getMissingPictures$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MissingPictureDto>>) => r.body as Array<MissingPictureDto>)
    );
  }

  /**
   * Path part for operation replaceMissingPictures
   */
  static readonly ReplaceMissingPicturesPath = '/backoffice-api/missing-pictures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceMissingPictures()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceMissingPictures$Response(params?: {
    context?: HttpContext
    body?: Array<MissingPictureDto>
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BackofficeApiService.ReplaceMissingPicturesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replaceMissingPictures$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceMissingPictures(params?: {
    context?: HttpContext
    body?: Array<MissingPictureDto>
  }
): Observable<void> {

    return this.replaceMissingPictures$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
