import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  AuthServiceProvider,
  MembershipDto,
  MembershipServiceInterface,
  PermissionDto,
  StorageService,
  UserApiService,
} from 'common';
import { distinctUntilChanged, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MembershipService implements MembershipServiceInterface {

  private _permissionSub = new ReplaySubject<PermissionDto[]>(1);

  static lastSelectedFactoryIdKey = 'lastSelectedFactoryId';
  private static selectedFactoryIdObs = new ReplaySubject<string>(1);

  private _selectedFactoryId: string | undefined = undefined;
  private waitingObservables: Subject<MembershipDto[]>[] = [];
  private _memberships: MembershipDto[] | undefined;
  private lastEmittedValue: string | undefined;
  private invitationInWaiting?: { invitationId: string; token: string };

  constructor(private storageService: StorageService,
              private authServiceProvider: AuthServiceProvider,
              private router: Router,
              private userApiService: UserApiService) {
    const permissions = this.storageService.getItems('permissions') as PermissionDto[];
    this._permissionSub.next(permissions);
  }

  loadMembership(forceEventEmit: boolean = false): Observable<any> {
    // load memberships
    return this.authServiceProvider.service.isLogged()
      .pipe(
        filter(logged => logged),
        mergeMap(() => this.userApiService.getMyMemberships()),
        tap((memberships: MembershipDto[]) => {
          this._memberships = memberships;
          // clean last selected membership if not present in the list
          if (!this._memberships.filter(value => value.subscribable.id === this._selectedFactoryId).length) {
            this.removeSelectedFactory();
          }
          this.waitingObservables.forEach((s: Subject<MembershipDto[]>) => {
            s.next(this._memberships);
            s.complete();
          });
          if (this._selectedFactoryId) {
            const filtered = memberships.filter(m => m.subscribable.id === this._selectedFactoryId);
            if (filtered.length) {
              this.emitSelectedFactory(this._selectedFactoryId, forceEventEmit);
              // store permissions
              this.setPermissions(filtered[0].permissions);
            } else {
              this.selectFactory(undefined).subscribe();
            }
          }
        })
      );
  }

  getSelectedMembershipListener(): Observable<MembershipDto | undefined> {
    return MembershipService.selectedFactoryIdObs
      .pipe(
        distinctUntilChanged(),
        filter(() => this._memberships !== undefined),
        map((factoryId) => factoryId ? this._memberships.filter(f => f.subscribable.id === factoryId)[0] : undefined)
      );
  }

  getSelectedMembershipObs(): Observable<MembershipDto | undefined> {
    return this.getSelectedMembershipListener()
      .pipe(take(1));
  }

  getSelectedFactoryIdObs(): Observable<string | undefined> {
    return MembershipService.selectedFactoryIdObs;
  }

  storeInvitationData(token: string, invitationId: string): void {
    this.invitationInWaiting = {
      token,
      invitationId
    };
  }

  hasInvitationInWaiting(): boolean {
    return !!this.invitationInWaiting;
  }

  acceptInvitationInWaiting(): Observable<any> | undefined {
    return undefined;
  }

  selectFactory(factoryId: string, fragment?: string, forceNavigation: boolean = true): Observable<any> {
    if (this._selectedFactoryId === factoryId) {
      return of(void 0);
    }
    const oldFactoryId = this._selectedFactoryId;
    // force refresh token to add the user IAM role
    this.authServiceProvider.service.forceRefreshToken();
    return this.getMemberships()
      .pipe(
        filter((memberships) => memberships.filter(m => m.subscribable.id === factoryId).length > 0),
        mergeMap((memberships: MembershipDto[]) => {
          // store factory
          const filteredMemberships = memberships.filter(m => m.subscribable.id === factoryId);
          if (factoryId && filteredMemberships.length) {
            this.storageService.storeItem(MembershipService.lastSelectedFactoryIdKey, factoryId);
            // set permission
            return this.setPermissions(filteredMemberships[0].permissions);
          } else {
            this.removeSelectedFactory();
            this.storageService.removeItem(MembershipService.lastSelectedFactoryIdKey);
            this.clearPermission();
            return of(void 0);
          }
        }),
        tap(() => {
          // emit event
          this._selectedFactoryId = factoryId;
          this.emitSelectedFactory(this._selectedFactoryId);
          // navigate if required
          if (forceNavigation || window.location.pathname.indexOf('/app/home') >= 0) {
            if (factoryId) {
              if (window.location.pathname.indexOf(this._selectedFactoryId) < 0) {
                this.router.navigate(['app', this._selectedFactoryId, 'navigation', 'factory-structure'])
                  .then(() => {
                    if (!!oldFactoryId) {
                      window.location.reload();
                    }
                  });
              }
            } else {
              this.router.navigate(['app', 'home']);
            }
          }
        })
      );
  }


  navigateToSelectedMembership() {
    // extract selected factory
    // priority to the url param
    const match = window.location.pathname.match(/\/app\/([a-z0-9-]+)\//);
    let lastSelectedFactoryId;
    if (match) {
      lastSelectedFactoryId = match[1];
    } else {
      lastSelectedFactoryId = this.storageService.getItem(MembershipService.lastSelectedFactoryIdKey);
    }
    if (lastSelectedFactoryId) {
      this.selectFactory(lastSelectedFactoryId, null, false).subscribe();
    }
    this.loadMembership(false).subscribe();
  }

  getMemberships(forceReload?: boolean): Observable<MembershipDto[]> {
    if (forceReload) {
      this.loadMembership(forceReload).subscribe();
    } else if (this._memberships) {
      return of(this._memberships);
    }
    const sbj = new Subject<MembershipDto[]>();
    this.waitingObservables.push(sbj);
    return sbj;
  }

  removeSelectedFactory() {
    this._selectedFactoryId = null;
    this.storageService.removeItem(MembershipService.lastSelectedFactoryIdKey);
  }

  clearFactoryIfSelected(factoryId: string): Observable<any> {
    if (this._selectedFactoryId === factoryId) {
      this._selectedFactoryId = undefined;
      this.storageService.removeItem(MembershipService.lastSelectedFactoryIdKey);
      MembershipService.selectedFactoryIdObs.next(undefined);
      this.clearPermission();
    }
    return of(void 0);
  }

  refreshSelectedFactory(): void {
  }

  private emitSelectedFactory(value: string, force: boolean = false) {
    if ((this.lastEmittedValue !== value || force) && this._memberships) {
      MembershipService.selectedFactoryIdObs.next(value);
      this.lastEmittedValue = value;
    }
  }

  setPermissions(permissions: PermissionDto[]): Observable<void> {
    this.storageService.storeItems('permissions', permissions);
    this._permissionSub.next(permissions);
    return of(void 0);
  }

  clearPermission(): any {
    this._permissionSub.next([]);
  }

  private listPermissions(): Observable<PermissionDto[]> {
    return this.getSelectedMembershipListener().pipe(
      filter(membership => !!membership),
      map((membership) => membership.permissions)
    );
  }

  hasPermission(permission: PermissionDto): Observable<boolean> {
    return this.getPermissions()
      .pipe(
        map(permissions => permissions?.indexOf(permission) >= 0)
      );
  }

  hasAtLeastOnePermissions(expectedPermissions: PermissionDto[]): Observable<boolean> {
    return this.listPermissions()
      .pipe(
        map(permissions => expectedPermissions.some(expectedPermission => permissions.indexOf(expectedPermission) >= 0))
      );
  }

  private getPermissions(): Observable<PermissionDto[]> {
    return this._permissionSub.asObservable()
      .pipe(take(1));
  }

  private addAndSelect(membership: MembershipDto) {
    this.loadMembership(false)
      .pipe(
        mergeMap(() => this.selectFactory(membership.subscribable.id))
      )
      .subscribe();
  }
}
